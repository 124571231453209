import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

interface PropTypes {
    title: string;
    value: string;
    error?: boolean;
}

function ChargeSummaryItem({ title, value, error = false }: PropTypes) {
    return (
        <>
            <Grid size={10} display="flex" justifyContent="flex-end">
                <Typography variant="h4" color={error ? 'error' : undefined}>{title}:</Typography>
            </Grid>

            <Grid size={2} display="flex" justifyContent="flex-end">
                <Typography variant="h4" color={error ? 'error' : undefined}>{value}</Typography>
            </Grid>
        </>
    )
}

export default ChargeSummaryItem;