import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import { useFormContext } from 'react-hook-form';
import ChargeSummaryItem from './ChargeSummaryItem';
import { DiscountFieldValue } from '../ChargeSchema';
import { useGetStudentLedger } from '@/api/users/students/GetStudentLedger';
import { useEffect, useState } from 'react';

function ChargeSummary() {
    const [newBalance, setNewBalance] = useState(0);
    const { watch, formState: { errors } } = useFormContext();

    const { data: studentLedger } = useGetStudentLedger({
        variables: {
            id: watch('student_user_id'),
        },
        initialData: [],
    });

    const finalTotal = watch('discounts')?.reduce((accumulator, discount: DiscountFieldValue) => {
        if (discount?.type_override === 'fixed') {
            return {
                discountTotal: accumulator.discountTotal + (discount?.amount_override / 100),
                discountedTotal: Math.max(0, accumulator.discountedTotal - (discount?.amount_override / 100)),
            }
        }

        if (discount?.type_override === 'percentage') {
            const discountAmount = (discount?.amount_override / 100) * accumulator.discountedTotal;
            console.log('Take of percentage: ', discount?.amount_override / 100);
            console.log('Total before: ', accumulator.discountedTotal);
            console.log('Take of amount: ', discountAmount);
            return {
                discountTotal: accumulator.discountTotal + discountAmount,
                discountedTotal: Math.max(0, accumulator.discountedTotal - discountAmount),
            }
        }

        return accumulator;
    }, {
        discountTotal: 0,
        discountedTotal: (watch('amount') ?? 0) / 100,
    });

    useEffect(() => {
        const currentBalance = Number(studentLedger[studentLedger.length - 1]?.balance);
        setNewBalance(currentBalance + finalTotal?.discountedTotal);
    }, [finalTotal]);
    console.log(errors)

    return (
        <Grid container rowSpacing={0} columnSpacing={3}>
            <ChargeSummaryItem title="Subtotal" value={((watch('amount') ?? 0) / 100).toFixed(2)} />
            <ChargeSummaryItem title="Discounts" value={finalTotal?.discountTotal.toFixed(2)} />
            <Grid size={12} py={1}><Divider /></Grid>
            <ChargeSummaryItem title="Total" value={finalTotal?.discountedTotal?.toFixed(2)} />
            <ChargeSummaryItem title="Deposit" value={((watch('deposit') ?? 0) / 100).toFixed(2)} error={!!errors['deposit']} />
            <Grid size={12} py={1}><Divider /></Grid>
            <ChargeSummaryItem title="New Student Balance" value={newBalance.toFixed(2)} />
        </Grid>
    );
}

export default ChargeSummary;